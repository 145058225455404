import React  from 'react';


function Landing() {
  return (
    <main className="bg">
      <div className="app">
        <div className="hero">
          <section className='info'>
            <img className="logo" src="./assets/logo_b.png" alt="Faraday"/>
            <h1>The <b>email client</b> unlike any the world has ever seen.</h1>
            <h3>Contextual. Clutter-free. Extraordinary.</h3>
            <img className='cta' src='./assets/cta.png' alt="Coming soon"/>
          </section>
          {/* <iframe className="video" width="600" height="337.5" src="https://www.youtube.com/embed/S-34op3ZFKI?si=PUgnJ5jHarADltay&amp;controls=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" referrerpolicy="strict-origin-when-cross-origin" allowFullScreen="allowFullScreen"></iframe> */}
          {/* <video className="video" src='./assets/demo.mp4'alt='Faraday' width='600px' controls type='video/mp4' poster='./assets/thumbnail.png'/> */}
        </div>
      </div>
      <div className="subtext"><a href="https://linkedin.com/in/neerajnathany/" target='_blank'>Founder who?</a></div>
      <footer>
        <span>© Hyprofound Technologies Private Limited, 2024</span>
      </footer>
    </main>
  );
}

export default Landing;
